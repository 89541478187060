import { Box, Modal, IconButton, styled } from "@mui/material";
import { Fullscreen } from "components/Fullscreen/Fullscreen";
import { Icon } from "components/Icons";
import { MaximizedGallery } from "components/MaximizedGallery/MaximizedGallery";
import PropTypes from "prop-types";

import React, { useEffect, useState } from "react";
import { getFocus, useAtom, useGet } from "state/jotai";
import { _fullscreen, active_project, previousUrl } from "state/store.global";
import { Controller, FreeMode, Keyboard, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Slider } from "../MaximizedGallery/MaximizedGallery.Slider";
import { useLocation } from "react-router-dom";
import { useBreak } from "hooks/useBreak";
import { _project } from "state/store.projects";
import { ImgWrapper } from "components/ImgContainer/ImgWrapper";
import { BackButton } from "components/Button/BackButton";

function Gallery({ unitId, images, thumbImg = [], hideThumbs, fullHeight, UnitDrawer, height, width, showFullImage=true }) {

  const here = useLocation().pathname;
  const atUnit = here.includes("unit");
  const [url] = useAtom(previousUrl);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const [, setIsFullscr] = useAtom(_fullscreen);
  const [activeIndex, setActiveIndex] = useState(0);
  const imageUrls = images.map((i) => i?.url);
  const isDesktop = useBreak("md_up");
  const isMobile = useBreak('md_up')

  const thumnUrls = thumbImg.map((i) => i?.url);

  // const showFullScreen = (ind) => {
  //   setActiveIndex(ind);
  //   setIsFullscr(true);
  // };

  const style = {
    position: "absolute",
    top: { xs: '30%', lg: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 'calc(100% - 32px)', lg: '80%'},
    // maxWidth: { md: "64%", xs: "100%" },
    boxShadow:
      "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
    outline: "none",
  };
  const [open, setOpen] = useState(false);
  const handleOpen = (i) => {
    setOpen(true);
    setActiveIndex(i);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <span>
      {showFullImage && isDesktop && atUnit && (
          <Box
            className="icon-hover-effect "
            style={{ display: "flex", cursor: "pointer", position: "absolute", top: 24, left: 24, zIndex: "500", backgroundColor: "#ffffffbf", borderRadius: '6px', alignItems: "center" }}
          >
            <BackButton  applyClass={false} style={{ position: "unset", gap: "8px", backgroundColor: 'transparent', padding: "8px" }} color={"#000"} />
          </Box>
        )}
      </span>

      <GallerySwiper
        images={imageUrls}
        activeIndex={activeIndex}
        onClickImage={handleOpen}
        hideThumbs={hideThumbs}
        fullHeight={fullHeight}
        UnitDrawer={UnitDrawer}
        thumbnails={thumnUrls}
        height={height}
        width={width}
        showFullImage={showFullImage}
        unitId={unitId}
      />

      {/* <Fullscreen>
        <MaximizedGallery initial={activeIndex} images={images} />
      </Fullscreen> */}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          " .MuiBackdrop-root": {
            backgroundColor: `${clr.primary}bf`
          },
        }}
      >

        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              zIndex: "1301",
              position: "absolute",
              right: 0,
              top: -40,
              color: "#fff",
            }}
          >

            <Icon icon="close" fontSize="inherit" />
          </IconButton>
          <Slider initial={activeIndex} images={images} />

        </Box>
      </Modal>
    </>
  );
}

Gallery.propTypes = {
  images: PropTypes.array,
  hideThumbs: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

Gallery.defaultProps = {
  images: [],
};

function GallerySwiper({
  images,
  activeIndex,
  onClickImage,
  hideThumbs,
  fullHeight,
  thumbnails,
  UnitDrawer,
  height = "100%",
  unitId,
  width,
  showFullImage
}) {
  const [swiper, setSwiper] = useState(null);
  const [swiperThumbs, setSwiperThumbs] = useState(null);
  const isMobile = useBreak('md_dn')
  const [index, setIndex] = useState(0)

  
  useEffect(()=> {
    swiper?.slideTo(0);
  }, [unitId])

  const handlePrev = (e) => {
    e.stopPropagation()
    swiper.slidePrev();
    setIndex(swiper.snapIndex)
  };

  const handleNext = (e) => {
    e.stopPropagation()
    swiper.slideNext();
    setIndex(swiper.snapIndex)
  };

  const handleClickImage = (ind) => {
    onClickImage(ind);
  };

  const handleClickThumb = (ind) => {
    swiper.slideTo(ind);
  };

  return (
    <Box
    sx={{
      userSelect: "none",
      "& .swiper-slide": {
        cursor: "pointer",
      },
      "& > .swiper .swiper-slide": {
        width: fullHeight ? "100%" : { xs: width ? width : "375px !important", sm: width ? width : "100% !important", lg: width ? width : "450px !important"} , 
        height: fullHeight ? "700px" : { xs: height ? height : "225px !important", sm: height ? height : "100% !important", lg: height ? height : "270px !important"},
      },
      "& .swiper-slide > img": {
        display: "block",
      },
      // height: fullHeight ? "100%" : "auto",
      // minHeight: fullHeight ? "100% !important" : height,
      // "& > .swiper": {
      //   minHeight: fullHeight ? "100%" : height,
      // },
      "& > .swiper .swiper-slide > img": {
        width: "100%",
        height: fullHeight ? "100%" : "auto",
        objectFit: "cover",
        // objectFit: "contain",
      },
      "& > .swiper .swiper-slide > div > img": {
        width: "100%",
        height: fullHeight ? "100%" : "auto",
        objectFit: "cover",
        // objectFit: "contain",
      },
    }}
    >
      <Swiper
        initialSlide={activeIndex}
        onSwiper={setSwiper}
        controller={{ control: swiperThumbs }}
        modules={[FreeMode, Keyboard, Thumbs, Controller]}
        slidesPerView={'auto'}
      >
        {images.map((image, i) => {
          return (

            <SwiperSlide key={i} style={{ minHeight: fullHeight && "100%", transform: "unset", width: "100%" }}
            // style={{ minWidth: fullHeight ? "100%" : isMobile ? "375px" : "450px", minHeight: fullHeight ? "100%" : isMobile ? "235px" : "270px" }}
            >
              <ImgWrapper img={image} onClick={() => showFullImage && handleClickImage(i)} />
            </SwiperSlide>
          )
        })}
        <>
          <Arrow sx={{ opacity: index ? 1 : 0.5 }} left onClick={(e)=> index && handlePrev(e)} UnitDrawer={UnitDrawer}>
            <div
              style={{
                borderRadius: " 0px 6px 6px 0px",
                backgroundColor: "#ffffffbf",
                display: "flex",
                height: "32px",
                width: "32px",
                padding: "8px 0px",
                alignItems: "center",
                justifyContent: "center",
                
              }}
            >
              <Icon icon="arrow-left" sx={{ color: "#000", fontSize: 20 }} />
            </div>
          </Arrow>
            <Arrow sx={{ opacity: images.length - 1 !== index ? 1 : 0.5 }} right onClick={(e)=> images.length - 1 !== index && handleNext(e)} UnitDrawer={UnitDrawer}>
            <div
              style={{
                borderRadius: " 6px 0px 0px 6px",
                backgroundColor: "#ffffffbf",
                display: "flex",
                height: "32px",
                width: "32px",
                padding: "8px 0px",
                alignItems: "center",
                justifyContent: "center",
                
              }}
            >
              <Icon icon="arrow-right" sx={{ color: "#000", fontSize: 20 }} />
            </div>
          </Arrow>
        </>
      </Swiper>

      {/* THUMB */}
      {!hideThumbs && (
        <Box sx={{ position: 'relative', bottom: "-5px", paddingBottom: '10px',  "& .swiper-slide": {
          height: { xs: "54px !important", sm: "90px !important", lg: "54px !important"} , 
          width: { xs: "91px !important", sm: "143px !important", lg: "91px !important"} , 
        }}}>
          <Swiper
            initialSlide={activeIndex}
            onSwiper={setSwiperThumbs}
            controller={{ control: swiper }}
            slidesPerView={4.5}
            freeMode={true}
            spaceBetween={5}
            modules={[Thumbs, Controller]}
          >
            {thumbnails.map((image, i) => (
              <SwiperSlide key={i} style={{ borderRadius :'6px', height: "100%" }}>
                 <ImgWrapper style={{borderRadius :'6px', height: "100%" }} img={image} onClick={() => handleClickThumb(i)} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
    </Box>
  );
}

GallerySwiper.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  activeIndex: PropTypes.number,
  onClickImage: PropTypes.func,
  hideThumbs: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

GallerySwiper.defaultProps = {
  images: [],
  activeIndex: 0,
  onClickImage: () => { },
};

// STYLES

const Arrow = styled(Box)(({ left, right, UnitDrawer }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  padding: "8px 0px",
  top: "50%",
  borderRadius: left ? "0px 6px 6px 0px" : "6px 0px 0px 6px",
  transform: "translateY(-50%)",
  left: left ? 0 : "unset",
  right: right ? 0 : "unset",
  width: "32px",
  height: "32px",
  backgroundColor: UnitDrawer ? "#ffffffbf" : "transparent",
  zIndex: 1000,
  cursor: "pointer",
  "& > span": {
    height: 20,
  },
}));

export default Gallery;
