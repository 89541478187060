import { useState } from "react";
import { _project } from "state/store.projects";
import { _isometry } from "state/store.isometry";
import { active_unit } from "state/store.global";
import { useGet, get } from "state/jotai";
import { Loading2 } from "components/Loading2/Loading2";
import { getpicks } from "utilities/utility.misc";

import { Table } from "./Table/Table";
import { Grid } from "./Grid/Grid";
import { Card } from "./Card/Card";

import { Caption } from "./List.Caption";
import { Scroll } from "./List.Scroll";
import { Content, Bottom } from "./List.style";
import { useClick } from "./List.click";

import { Box } from "@mui/material";
import { useBreak } from "hooks/useBreak";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import CustomSnackbar from "components/ToastMessage/CustomSnackbarToast";

export const List = () => {
  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const isDesktop = useBreak("md_up");

  const pick = { background: "#ff00ff" };
  // const unit         = useGet(active_unit)
  const units = get(_project, "units", "Units", false);
  const raw = get(_project, "unitsraw", "Units");
  const unitErrMsg = get(_project, "units", "message");

  const text = get(_project, "cardtext");
  const picks = get(_project, "handpicked");
  const extra = getpicks(raw, picks);
  const count = units?.length ?? [];
  const total = raw?.length ?? [];
  const mode = useState("card");
  const click = useClick(units);
  const caption = { count, mode: mode[0], setMode: mode[1], total };
  
  // Function to open Snackbar
  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Function to close Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

    
  const list = { units, extra, text, pick, unitErrMsg, click, mode: mode[0], snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose };

  return (
    <>
      <Box sx={{ height: 10 }} />
      <Caption {...caption} />
      <Box style={{ height: 10 }} />
      <div style={{ border: "0px solid red" }}>
        {units ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: {
                md: "calc(100vh - 200px)",
                xs: "calc(100vh - 250px)",
              },
              justifyContent: "space-between",
            }}
          >
            <Content mode={mode[0]} units={units?.length} isDesktop={isDesktop}>
              {/* { mode[0] === 'table' && <Table {...list}/> } */}
              {mode[0] === "card" && <Card {...list} />}
              {mode[0] === "grid" && <Grid {...list} />}
              <div style={{ paddingTop: "40px" }}>
                <PoweredBy />
              </div>
            </Content>
          </Box>
        ) : (
          <Loading2 show />
        )}
           {/* Snackbar */}
      <CustomSnackbar message={snackbarMessage} onClose={handleSnackbarClose} open={snackbarOpen}  />
      </div>
    </>
  );
};
